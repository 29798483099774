@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Global Styles */
body.home-route {
  @apply bg-mpurple to-gray-900 w-screen ;
}

body.other-route {
 @apply bg-white w-screen;
}

/* Brand Colors */ 
.tint-mlight {
  filter: brightness(0) saturate(100%) invert(93%) sepia(12%) saturate(1013%) hue-rotate(316deg) brightness(104%) contrast(90%);
}

@keyframes light-reveal {
  0% {
    opacity: 0;
    filter: brightness(0) saturate(100%) invert(93%) sepia(12%) saturate(1013%) hue-rotate(316deg) brightness(0%) contrast(90%);
  }
  50% {
    opacity: 0.5;
    filter: brightness(0) saturate(100%) invert(93%) sepia(12%) saturate(1013%) hue-rotate(316deg) brightness(52%) contrast(90%);
  }
  100% {
    opacity: 1;
    filter: brightness(0) saturate(100%) invert(93%) sepia(12%) saturate(1013%) hue-rotate(316deg) brightness(104%) contrast(90%);
  }
}

.logo-reveal {
  animation: light-reveal 4s ease-in-out forwards;
}

/* Brand font */

.font-outfit {
font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


/* Spotlights animations */ 
.spotlight {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  mix-blend-mode: screen;
  animation: move 20s infinite, spotlight-reveal 2s ease-in-out forwards;
}

.spotlight:nth-child(1) {
  background: radial-gradient(circle at 50% 50%, #602D8F 0%, transparent 50%);
  animation-delay: 0s, 0s;
}

.spotlight:nth-child(2) {
  background: radial-gradient(circle at 50% 50%, #DD3A8F 0%, transparent 50%);
  animation-delay: -6.66s, 0.5s;
}

.spotlight:nth-child(3) {
  background: radial-gradient(circle at 50% 50%, #EF5223 0%, transparent 50%);
  animation-delay: -13.33s, 1s;
}

@keyframes move {
  0%, 100% {
    transform: translate(-20%, -15%) scale(1.1);
  }
  20% {
    transform: translate(15%, 25%) scale(0.9);
  }
  40% {
    transform: translate(25%, -20%) scale(1.2);
  }
  60% {
    transform: translate(-15%, 20%) scale(0.8);
  }
  80% {
    transform: translate(20%, 10%) scale(1);
  }
}

@keyframes spotlight-reveal {
  0% {
    opacity: 0;
    filter: brightness(0);
  }
  100% {
    opacity: 0.7;
    filter: brightness(1);
  }
}